const localFeaturePack = {
    'shareStartDraw': true,
    // 'boneTabs': false
    'allSelfStart':true,
    'lowFiv1':false,
    boneSort:true
}

export const getLocalFeatureConfig = () => {
    const localFeatureData = localStorage.getItem('localFeatureConfig')
    try {
        const config = JSON.parse(localFeatureData)

        const fullPack = {
            ...localFeaturePack,
            ...config,
        }

        Object.keys(fullPack)
            .filter(key => !localFeaturePack[key] && localFeaturePack[key] !==false )
            .forEach((key) => {
                delete fullPack[key]
            })

        return fullPack;
    } catch (error) {
        console.log("🚀 ~ file: localFeatureConfig.js:12 ~ getLocalFeatureConfig ~ error:", error)
        return {}
    }
}


export const setLocalFeatures = (update) => {
    const config = getLocalFeatureConfig();

    try {
        const updated = {
            ...config,
            ...update
        }

        const updateData = JSON.stringify(updated)

        localStorage.setItem('localFeatureConfig', updateData)
    } catch (error) {
        console.log("🚀 ~ file: localFeatureConfig.js:28 ~ setLocalFeatures ~ error:", error)
    }
}


export const getLocalFeature = (key) => {

    const features = getLocalFeatureConfig()

    const feature = features[key];

    return feature
}
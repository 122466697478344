import { useRef, useCallback } from "react";
import { grabInterSec } from "./grabPlaneIntect";
import { useOrbitalRef } from "./useOrbitalRef";
import { onPositionData } from "./onPositionData";
// import * as THREE from "three";

let lastTouched;

export const setLastTouched = (bone) => {
  lastTouched = bone;
};

export const getLastTouched = () => {
  return lastTouched;
};

let BoardObject;

let boardDown = false;

export const setBoardDown = (isDown) => {
  boardDown = isDown;
};

export const getBoardDown = () => {
  return boardDown;
};

export const useTouchHooks = () => {
  const ref = useRef();
  const { orbitalRef } = useOrbitalRef();
  const onPointerDown = (event, isBoard) => {
    if (isBoard) {
      BoardObject = event.eventObject;
      setBoardDown(event.clientX);
    }
  };
  const onPointerMove = useCallback(
    (event, isBoard) => {
      event.stopPropagation();
      const last = getLastTouched();
      // const planeIntersect = grabInterSec(event);
      // console.log("🚀 ~ useTouchHooks ~ planeIntersect:", planeIntersect)
      // const parent = window.boardref

      // const mat = new THREE.Matrix4();
      // mat.makeRotationY(-parent.rotation.y);
      // planeIntersect.applyMatrix4(mat);
      // const { x, z } = planeIntersect;

      // const xScaleFactor = parent.scale.x || 1;
      // const shiftedX = x - parent.position.x;
      // const scaledX = shiftedX / xScaleFactor;

      // const zScaleFactor = parent.scale.z || 1;
      // const shiftedZ = z - parent.position.z;
      // const scaledZ = shiftedZ / zScaleFactor;
      // const scaledPoistion = new THREE.Vector3(scaledX, 0, scaledZ);
      // console.log("🚀 ~ useTouchHooks ~ scaledPoistion:", scaledPoistion)



      if (isBoard && BoardObject) {
        onPositionData(event);
      }
      if (last) {
        orbitalRef.enabled = false;
        const planeIntersect = grabInterSec(event);


        last.setParent(event);
        last.setPosition(planeIntersect);
      } else if (getBoardDown() !== false) {
        const diff = event.clientX - getBoardDown();

        if (Math.abs(diff) > 10 && window.canRotate) {
          const halfway = event.clientY > window.innerHeight / 2;

          BoardObject.rotation.y =
            BoardObject.rotation.y +
            (halfway ? 1 : -1) * (event.movementX / 100);
        }
      }
    },
    [orbitalRef]
  );

  const onPointerUp = useCallback(
    (event) => {
      event.stopPropagation();
      setBoardDown(false);
      const last = getLastTouched();

      if (last) {
        const { onDrop = () => {} } = last;
        onDrop();
        setLastTouched(undefined);
      }
      if (orbitalRef) {
        orbitalRef.enabled = true;
      }
    },
    [orbitalRef]
  );
  return {
    ref,
    onPointerDown,
    onPointerMove,
    onPointerUp,
  };
};

import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import moment from "moment";
import { gamesCollectionRef, getCurrentUser, firebase } from "../firebase";
import { shouldUpdateCohadoState } from "./shouldUpdateCohadoState";
import { startNewCloudGame } from "./startNewCloudGame";
import { checkForUpdatedKeys } from "../useThreehadoStates/checkForUpdatedKeys";
import { initRandomBones } from "../useThreehadoStates/initRandomBones";
import { buildDrawBones } from "../buildDrawBones";
import { calculateCoe } from "../useThreehadoStates/calculateCoe";
import { quotes } from "../ActionBar/quotes";
import {
  setQuotesIfBridge,
  showBridgeAlertIfNeeded,
} from "./setQuotesIfBridge";
import { getLiveQuotes } from "./getLiveQuotes";
import { shouldRefreshForRound } from "./shouldRefreshForRound";
import { shouldShowBeatMsg } from "./shouldShowBeatMsg";
import { buildGameCardData } from "./buildGameCardData";
import { sleep } from "./sleep";
import { handleNextRound } from "./handleNextRound";
import { getLocalFeature } from "../../KeyMenu/localFeatureConfig";
import { shuffle } from "spureapi/shuffle";

let NextBlock = false;
const getLastPic = async (cutRefreshCanvas) => {
  try {
    const small = await cutRefreshCanvas();
    const part = small ? await sleep(200) : "";
    const lastPic64 = small ? small.toDataURL() : "";
    return lastPic64;
  } catch (error) {
    console.log("🚀 ~ file: useCloudGame.js:144 ~ error", error);
  }
};

export const useCloudGame = (props) => {
  const {
    cohadoStates,
    shouldUpdateBoneStates,
    pointSections = [],
    setHidePlatforms = () => {},
    cutRefreshCanvas = () => {},
  } = props;

  const [cloudGame, setCloudGame] = useState(undefined);

  const ref = useRef({
    ices: [],
    addedUsers: {},
    dataChannels: {},
    streams: {},
  });

  const gameId = useMemo(
    () => (cloudGame && cloudGame.id ? cloudGame.id : undefined),
    [cloudGame]
  );

  useEffect(() => {
    try {
      if (gameId) {
        const lastTouched = moment();
        const lastPack = {
          lastTouched: lastTouched.valueOf(),
          lastTouchedFormated: lastTouched.format("LLL"),
        };
        gamesCollectionRef.doc(gameId).update(lastPack);
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: useCloudGame.js:66 ~ useEffect  lastPack ~ error:",
        error
      );
    }
  }, [gameId]);

  const gameSub = useRef(() => {});

  useEffect(() => {
    shouldUpdateCohadoState({
      cohadoStates,
      cloudGame: ref.current.cloudGame,
      pointSections,
    });
  }, [cohadoStates, ref]);

  const onCloudGameUpdateFromWatch = async (cloudGameRef) => {
    try {
      const user = getCurrentUser();

      const doc = await cloudGameRef.get();

      const rawGameData = {
        ...doc.data(),
      };

      const gameData = {
        ...rawGameData,
        //  ...(rawGameData.demo ? (rawGameData.demoData) : {})
      };

      // const { rounds = [] } = gameData;

      // shouldRefreshForRound({ roundNumber: rounds.length, gameId: doc.id });

      const myCloudUserRef = Object.keys(gameData)
        .map((key) => gameData[key])
        .find((gameSlice) => gameSlice.isPlayerData && gameSlice.id == user.id);

      const cloudGameData = {
        id: cloudGameRef.id,
        ...gameData,
        setShowHand: async (showBoneHand) => {
          if (myCloudUserRef && myCloudUserRef.showHand != showBoneHand) {
            await gamesCollectionRef.doc(cloudGameRef.id).update({
              [user.id]: {
                ...myCloudUserRef,
                stamp: new Date().getTime(),
                showHand: showBoneHand,
              },
            });
          }
        },
      };

      const { allPlayers = [] } = cloudGameData;

      const inGame = allPlayers.find((playa) => playa.email === user.email);

      if (inGame) {
        setCloudGame(cloudGameData);

        if (!myCloudUserRef) {
          const inviteKey = user.email.split(".").join("-");
          const { isWatcher = false, screenName = "" } =
            cloudGameData[inviteKey] || {};

          const playaPac = {
            isPlayerData: true,
            stamp: new Date().getTime(),
            id: user.id,
            email: user.email,
            screenName,
            isWatcher,
          };

          const allPlayersUpdate = allPlayers.map((playa) => {
            if (playa.email === user.email) {
              return playaPac;
            }

            return playa;
          });

          const allEmails = allPlayersUpdate.map((player) => {
            return player.email;
          });
          await gamesCollectionRef.doc(cloudGameData.id).update({
            [user.id]: {
              ...playaPac,
            },
            [inviteKey]: firebase.firestore.FieldValue.delete(),
            allPlayers: allPlayersUpdate,
            allEmails,
          });
        }

        const updatedKeys = checkForUpdatedKeys(ref.current.cloudGame, {
          ...cloudGameData,
        });
        ref.current.cloudGame = cloudGameData;

        if (updatedKeys && updatedKeys.indexOf("cohadoStates") > -1) {
          shouldUpdateBoneStates({ cloudGameData });
        }
      }
    } catch (error) {
      console.log("onCloudGameUpdateFromWatch -> error", error);
    }
  };

  const callNextTurn = useCallback(
    async (props = {}) => {
      const {
        state = "selectHandBone",
        gameUpdate = {},
        turnData = {},
      } = props;

      try {
        const {
          playerOrderList = [],
          turn,
          beats = 0,
          turns = [],
          usedQuotes = [],
          quoteBay = [],
          lastPic64,
        } = cloudGame;

        if (turn && !NextBlock) {
          NextBlock = true;
          const idOrder = playerOrderList.map((player) => player.id);
          const { id: turnId } = turn;
          const step = idOrder.indexOf(turnId);
          if (step > -1) {
            const isLastStep = step < playerOrderList.length - 1;
            const nextStep = isLastStep ? step + 1 : 0;

            const { isPlayerData, ...nextTurn } = playerOrderList[nextStep];

            const { boneStates } = cohadoStates;
            const boneList = Object.keys(boneStates).map(
              (boneKey) => boneStates[boneKey]
            );

            const latestInjectionNumber = boneList.reduce(
              (largestInjecttionNUmber, boneState) => {
                const { injectionNumber = 0 } = boneState;
                return injectionNumber > largestInjecttionNUmber
                  ? injectionNumber
                  : largestInjecttionNUmber;
              },
              0
            );

            const { boneCount = 0 } = turn;

            const liveboneCount = boneList.length;

            const tookBeat = liveboneCount && liveboneCount === boneCount;

            if (liveboneCount > 0 && tookBeat) {
              gameUpdate.beats = beats + 1;
            }
            const coe = calculateCoe({
              pointSections,
              beats: gameUpdate.beats,
            });

            const moStamp = moment();

            const milliStamp = moStamp.valueOf();

            const forStamp = moStamp.format("LLL");

            const turnsUpdateData = {
              ...turnData,
              tookBeat,
              state,
              ...nextTurn,
              coe,
              stamp: new Date().getTime(),
              boneCount: liveboneCount,
              latestInjectionNumber,
              milliStamp,
              forStamp,
              // pointSections,
              // boneStates, // eventually move from here
            };

            const allTurns = [...turns, turnsUpdateData].map((turn) => {
              const {
                pointSections = {},
                // boneStates,
                ...nops
              } = turn;
              // some clean up for old games
              return {
                ...nops,
              };
            });

            let cloudGameuUdate = {
              ...gameUpdate,
              turn: {
                ...turnsUpdateData,
                pointSections,
                boneStates,
              },
              turns: [...allTurns],
              usedQuotes,
              quoteBay,
            };

            if (nextTurn) {
              const lastPic64 = await getLastPic(cutRefreshCanvas);
              cloudGameuUdate = setQuotesIfBridge({ cloudGameuUdate });
              if (lastPic64) {
                cloudGameuUdate.lastPic64 = lastPic64;
              }
              await gamesCollectionRef
                .doc(cloudGame.id)
                .update(cloudGameuUdate);
              NextBlock = false;
            }
          }
        }
      } catch (error) {
        console.log("🚀 ~ file: useCloudGame.js:193 ~ error:", error);
      }
    },
    [cohadoStates, cloudGame, cutRefreshCanvas, pointSections]
  );

  const setPlayerOrder = async (props) => {
    const { cloudGame, playerOrderList, bonesPerPlayer } = props;
    cutRefreshCanvas(true);

    const [player1] = playerOrderList;
    const well = initRandomBones();

    const liveQuotes = await getLiveQuotes();

    const [noPualoQuotes, pauloQuotes] = quotes.reduce(
      ( [others, pualo],quote) => {
        const { author } = quote;
  
        if (author === "Paulo Gregory") {
          pualo.push(quote);
        } else {
          others.push(quote);
        }
  
        return [others, pualo];
      },
      [[], []]
    );

    const sort1 = [
      ...shuffle(liveQuotes),
      ...shuffle(noPualoQuotes),
      ...shuffle(pauloQuotes).slice(0, 3),
    ];


    const quoteBay = shuffle(sort1).slice(0, 42);

    const withTightGridPositions = buildDrawBones({ well });

    const { isPlayerData, ...nextTurn } = player1;

    await gamesCollectionRef.doc(cloudGame.id).update({
      well: withTightGridPositions,
      playerOrderList,
      bonesPerPlayer,
      quoteBay,
      turn: {
        ...nextTurn,
        state: "pickBones",
        stamp: new Date().getTime(),
      },
    });
  };

  const watchCloudGame = async (gameId) => {
    const cloudGameRef = gamesCollectionRef.doc(gameId);

    const unsubscribe = cloudGameRef.onSnapshot({
      next: (snapshot) => {
        onCloudGameUpdateFromWatch(cloudGameRef);
      },
      error: (error) => {
        console.log("watchCloudGame -> error", error);
      },
    });

    gameSub.current = () => {
      unsubscribe();
    };
  };

  const nextRound = async () => {
    const refGame = ref.current.cloudGame || {};
    handleNextRound({ refGame });
  };

  const callEndGame = async () => {
    try {
      const {
        turn,
        beats = 0,
        turns = [],
        usedQuotes = [],
        quoteBay = [],
      } = cloudGame;
      const gameUpdate = {};
      const { boneStates } = cohadoStates;
      const boneList = Object.keys(boneStates).map(
        (boneKey) => boneStates[boneKey]
      );

      const latestInjectionNumber = boneList.reduce(
        (largestInjecttionNUmber, boneState) => {
          const { injectionNumber = 0 } = boneState;
          return injectionNumber > largestInjecttionNUmber
            ? injectionNumber
            : largestInjecttionNUmber;
        },
        0
      );

      const { boneCount = 0 } = turn;

      const liveboneCount = boneList.length;

      const tookBeat = liveboneCount && liveboneCount === boneCount;

      if (liveboneCount > 0 && tookBeat) {
        gameUpdate.beats = beats + 1;
      }
      const coe = calculateCoe({ pointSections, beats: gameUpdate.beats });

      const moStamp = moment();

      const milliStamp = moStamp.valueOf();

      const forStamp = moStamp.format("LLL");

      const turnsUpdateData = {
        ...turn,
        tookBeat,
        state: "endGame",
        coe,
        stamp: new Date().getTime(),
        boneCount: liveboneCount,
        latestInjectionNumber,
        milliStamp,
        forStamp,
      };

      turnsUpdateData.madeBridge = false;
      delete turnsUpdateData.quote;

      const allTurns = [...turns, turnsUpdateData].map((turn) => {
        const { pointSections = {}, boneStates, ...nops } = turn;
        // some clean up for old games
        return {
          ...nops,
        };
      });

      let cloudGameuUdate = {
        turn: {
          ...turnsUpdateData,
          pointSections,
          boneStates,
        },
        turns: [...allTurns],
        usedQuotes,
        quoteBay,
      };

      const lastPic64 = await getLastPic(cutRefreshCanvas);
      cloudGameuUdate = setQuotesIfBridge({ cloudGameuUdate });
      if (lastPic64) {
        cloudGameuUdate.lastPic64 = lastPic64;
      }

      await gamesCollectionRef.doc(cloudGame.id).update(cloudGameuUdate);
    } catch (error) {
      console.log("🚀 ~ file: call endgame ~ error:", error);
    }
  };

  const user = getCurrentUser();

  const iAmhost =
    user && cloudGame && cloudGame.creator
      ? cloudGame.creator.id == user.id
      : false;

  const turnState =
    cloudGame && cloudGame.turn ? cloudGame.turn.state : undefined;

  const turn = useMemo(
    () => (cloudGame && cloudGame.turn ? cloudGame.turn : undefined),
    [cloudGame]
  );

  const turnStamp = useMemo(
    () => (cloudGame && cloudGame.turn ? cloudGame.turn.stamp : undefined),
    [cloudGame]
  );

  const turnBoneCount = useMemo(
    () =>
      cloudGame && cloudGame.turn ? cloudGame.turn.boneCount || 0 : undefined,
    [cloudGame]
  );
  const isMyTurn = useMemo(
    () => (user && cloudGame && turn ? turn.id == user.id : false),
    [cloudGame, turn, user]
  ); // on every turnState , check for "cohado drop"

  const callPieceOutForPlayer = useCallback(async () => {
    const user = getCurrentUser();

    const {
      playerOrderList = [],
      cohadoStates = {},
      playerHands = {},
      well = [],
      turn = {},
      beats = 0,
    } = cloudGame;

    const { boneStates = {} } = cohadoStates;

    let turnData = { ...turn };

    if (isMyTurn) {
      const idOrder = playerOrderList.map((player) => player.id);
      const { id: turnId } = turn;
      const step = idOrder.indexOf(turnId);
      if (step > -1) {
        const isLastStep = step < playerOrderList.length - 1;
        const nextStep = isLastStep ? step + 1 : 0;

        const { isPlayerData, ...nextTurn } = playerOrderList[nextStep];

        const boneList = Object.keys(boneStates).map(
          (boneKey) => boneStates[boneKey]
        );

        const latestInjectionNumber = boneList.reduce(
          (largestInjecttionNUmber, boneState) => {
            const { injectionNumber = 0 } = boneState;
            return injectionNumber > largestInjecttionNUmber
              ? injectionNumber
              : largestInjecttionNUmber;
          },
          0
        );

        const liveboneCount = boneList.length;

        const moStamp = moment();

        const milliStamp = moStamp.valueOf();

        const forStamp = moStamp.format("LLL");

        const coe = calculateCoe({
          pointSections,
          beats,
        });

        turnData = {
          ...turn,
          ...nextTurn,
          coe,
          stamp: new Date().getTime(),
          boneCount: liveboneCount,
          latestInjectionNumber,
          milliStamp,
          forStamp,
        };
      }
    } // if ismyturn

    const newPlayerList = playerOrderList.filter((player) => {
      return player.id !== user.id;
    });

    const playerData = cloudGame[user.id] || {};

    const hand = playerHands[user.id] || [];

    const liveHand = hand.filter((bone) => !boneStates[bone.boneKey]);

    const withTightGridPositions = buildDrawBones({
      well: [...well, ...liveHand],
    });

    const gameUpdate = {
      turn: {
        ...turnData,
      },
      [user.id]: {
        ...playerData,
        piecedOut: true,
        piecedOutStamp: new Date().getTime(),
        piecedOutDate: new Date().toLocaleString(),
      },
      playerHands: {
        ...playerHands,
        [user.id]: [],
      },
      playerOrderList: [...newPlayerList],
      well: withTightGridPositions,
    };

    await gamesCollectionRef.doc(cloudGame.id).update({
      ...gameUpdate,
    });

    window.location.href = "/dash";
  }, [cloudGame, cohadoStates, isMyTurn, pointSections]);

  const initCloudGame = async (gameId) => {
    watchCloudGame(gameId);
  };

  const setPlayerHand = useCallback(
    async (props) => {
      const { initialHand, selectedHand, well, endWellPick, unlockShowWell } =
        props;

      if (!endWellPick) {
        cutRefreshCanvas(true);
      }

      const user = getCurrentUser();

      const refGame = ref.current.cloudGame || {};

      const { playerHands = {}, initialHands = {} } = refGame;

      const hasHand = playerHands[user.id];
      if (!hasHand || endWellPick) {
        const handupdate = {
          ...playerHands,
          [user.id]: [...selectedHand],
        };

        const gameUpdate = {
          playerHands: {
            ...handupdate,
          },
        };

        if (unlockShowWell) {
          gameUpdate.beats = (refGame.beats || 0) + 1;
        }

        if (well) {
          gameUpdate.well = well;
        }

        if (initialHand) {
          const initialHandsUpdate = {
            ...initialHands,
            [user.id]: [...selectedHand],
          };
          gameUpdate.initialHands = initialHandsUpdate;
        }

        await gamesCollectionRef.doc(refGame.id).update({
          ...gameUpdate,
        });
      }
    },
    [ref.current]
  );

  const onHandSort = useCallback(
    async (props) => {
      const { hand } = props;

      const user = getCurrentUser();

      const refGame = ref.current.cloudGame || {};

      const { playerHands = {} } = refGame;

      const handupdate = {
        ...playerHands,
        [user.id]: [...hand],
      };

      const gameUpdate = {
        playerHands: {
          ...handupdate,
        },
      };

      await gamesCollectionRef.doc(refGame.id).update({
        ...gameUpdate,
      });
    },
    [ref.current]
  );

  useEffect(() => {
    return () => gameSub.current();
  }, []);

  useEffect(() => {
    if (turnState && turnState === "endGame") {
      const myModal = new window.bootstrap.Modal(
        document.getElementById("scoreSummary"),
        {
          keyboard: true,
          backdrop: false,
        }
      );

      myModal.show();
    }
  }, [cloudGame]);

  useEffect(() => {
    showBridgeAlertIfNeeded(cloudGame); //
    shouldShowBeatMsg(cloudGame);
  }, [turnStamp]);

  const localHand = useMemo(() => {
    if (cloudGame) {
      const user = getCurrentUser();

      const { playerHands = {} } = cloudGame;
      const bones = playerHands[user.id] || [];
      const getXPos =
        bones.length > 7
          ? (i) => i * 0.22 - 0.75 - 0.7
          : (i) => i * 0.25 - 0.75;

      const handPositions = bones.map((bone, i) => {
        return {
          ...bone,
          initPosition: {
            rotation: getLocalFeature("boneSort")
              ? bone.initPosition.rotation
              : 0,
            xGridPoint: getXPos(i),
            zGridPoint: 0,
            yFloat: 0.11,
          },
        };
      });

      return handPositions;
    }
    return [];
  }, [cloudGame]);

  const reset = useCallback(async () => {
    if (cloudGame) {
      const { playerOrderList = [] } = cloudGame;

      const [player1] = playerOrderList;

      const { isPlayerData, ...nextTurn } = player1;

      const resetGameData = {
        turn: {
          ...nextTurn,
          boneCount: 0,
          latestInjectionNumber: 0,
          state: "selectHandBone",
        },
        cohadoStates: {
          boneStates: {},
        },
      };

      Object.keys(cloudGame)
        .filter((key) => cloudGame[key] && cloudGame[key].isPlayerData)
        .forEach((key) => {
          resetGameData[key] = {
            ...resetGameData[key],
            showDrawWell: false,
          };
        });

      await gamesCollectionRef.doc(cloudGame.id).update({
        ...resetGameData,
      });

      window.location.reload();
    }
  }, [cloudGame]);

  const onClickSaveSummaryImage = useCallback(
    async (props) => {
      const { cloneDataUrl } = props;
      await buildGameCardData({ cloudGame, setHidePlatforms, cloneDataUrl });
    },
    [cloudGame, setHidePlatforms]
  );

  return {
    callPieceOutForPlayer,
    callEndGame,
    nextRound,
    reset,
    turnState,
    localHand,
    callNextTurn,
    setPlayerOrder,
    onHandSort,
    iAmhost,
    isMyTurn,
    turn,
    cloudGame,
    setPlayerHand,
    initCloudGame,
    startNewCloudGame,
    onClickSaveSummaryImage,
  };
};

import { ZeroLevelCollapse } from "./ZeroLevelCollapse";
import { MetaGameCollapse } from "./MetaGameCollapse";
import { NextLevelCollapse } from "./NextLevelCollapse";
import { openCohadoMap } from "./CohadoMapModal/openCohadoMap";

export const GuidelinesCollapse = () => {
  return (
    <>
      <div className="d-flex align-items-center">
        <p
          className="key-menu-item"
          data-bs-toggle="collapse"
          data-bs-target=".guidelines-collapse"
          aria-expanded="false"
          aria-controls="guidelines-collapse"
        >
          Guidelines
        </p>
        <img
          className="mx-3"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            openCohadoMap();
          }}
          style={{
            height: 60,
          }}
          src="/images/gamemap_paper_03_logo.png"
          type="button"
        />
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://firebasestorage.googleapis.com/v0/b/big-hado.appspot.com/o/introVideos%2FCohado%20Virtual%20Demo%202.0%20Hi%20Res.mp4?alt=media&token=fa28bd1a-41b3-4cd6-923d-df7953ddf5e5"
        >
          <img
            style={{
              height: 60,
            }}
            src="/images/tv_videopick.png"
            alt=""
          />
        </a>
      </div>
      <div
        className="collapse info-collapse guidelines-collapse"
        id="guidelines-collapse"
      >
        <div className="card card-body  p-0">
          <ZeroLevelCollapse />
          <MetaGameCollapse />
          <NextLevelCollapse />
          <p> --- Play on Player!</p>
          <div className="d-flex justify-content-center">
            <img
              data-bs-toggle="collapse"
              data-bs-target=".guidelines-collapse"
              aria-expanded="false"
              aria-controls="guidelines-collapse"
              src="images/closemenuarrow.png"
              style={{
                position: "relative",
                width: 50,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

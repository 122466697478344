import { useMemo, useEffect } from "react";
import { OrbitControls } from "@react-three/drei";
import { CohadoBoardMesh } from "./CohadoBoardMesh";
import { HexGrid } from "./HexGrid";
import { HandPlatform } from "./HandPlatform";
import { BoneSwitch } from "./BoneSwitch";
import { orbitalRef$, registerOrbitalRef } from "./ThreeUtills/useOrbitalRef";
import { useCallback } from "react";
import { WellPlatform } from "./WellPlatform";
import { BaseWellMesh } from "./WellPlatform/BaseWellMesh";
import { useThree } from "@react-three/fiber";
import * as THREE from "three";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { setLastCam, getLastCam } from "./lastCam";
import { MoveHexes } from "./HexGrid/MoveHex";
import { setCurrentPlatform } from "./PlatformControl/useDragPlatBlock";
import { Cursors } from "./Cursors";
import { getCheathado } from "./getCheathado";
import { getLocalFeature } from "../KeyMenu/localFeatureConfig";
import { calcGridROtaion } from "./BoneMeshSL/getGridCoords";
// import { SpecPoint } from "./SpecPoints";

const lowFiv1 = getLocalFeature("lowFiv1");
const boneSort = getLocalFeature("boneSort");

export const CohadoScene = (props) => {
  const { scene, camera } = useThree();

  useEffect(() => {
    if (!lowFiv1) {
      try {
        new RGBELoader()
          .setPath("/hdrs/")
          .load("rural_landscape_2k.hdr", function (texture) {
            texture.mapping = THREE.EquirectangularReflectionMapping;
            scene.environment = texture;
          });
      } catch (error) {
        console.log(
          "🚀 ~ file: CohadoScene.js ~ line 36 ~ useEffect ~ error",
          error
        );
      }
    }
  }, [scene]);

  useEffect(() => {
    const lastCam = getLastCam();
    if (lastCam) {
      camera.position.set(
        lastCam.position.x,
        lastCam.position.y,
        lastCam.position.z
      );
      camera.rotation.set(
        lastCam.rotation.x,
        lastCam.rotation.y,
        lastCam.rotation.z
      );
      const controls = orbitalRef$.getValue();
      if (controls) {
        controls.target = lastCam.userData.controltarget;
      }
    } else {
      camera.position.y = 2;
      camera.position.z = 0.5;
      camera.position.x = 0;
      camera.rotation.set(-1.3258176636680323, 0, 0);
    }
    setLastCam(camera);
  }, [camera]);

  const {
    showWell,
    hidePlatforms,
    unlockShowWell,
    bones,
    removeBone,
    onEnd,
    drawBones,
    onHandSort,
    setunlockShowWell = () => {},
    selectedDrawbones,
    selectDrawWellbone,
    onDrawBoneSelect,
    boneStates,
    setPlayerHand,
    isMyTurn,
    turn,
    localHand,
    boardRotation,
    togglePlatformControl,
    setShowPalette,
    drawWell,
  } = props;
  const onDrop = useCallback(
    (dropped) => {
      const { parentId, glyphs, ...boneItem } = dropped;
      const platform = parentId === "platform";
      const board = parentId === "board";
      const wellPlatform = parentId === "wellPlatform";
      const cheatHado = getCheathado();
      const inLocalHand = localHand
        .map((bone) => bone.boneKey)
        .find((boneKey) => boneKey === dropped.boneKey);

      const liveBoneCount = Object.keys(boneStates).length;

      const boneList = Object.keys(boneStates).map(
        (boneKey) => boneStates[boneKey]
      );

      const liveInjectionNumber = boneList.reduce(
        (largestInjecttionNUmber, boneState) => {
          const { injectionNumber = 0 } = boneState;
          return injectionNumber > largestInjecttionNUmber
            ? injectionNumber
            : largestInjecttionNUmber;
        },
        0
      );

      const turnBoneCount = turn.boneCount;
      const turnInjectionNumber = turn.latestInjectionNumber;
      const dropInBoneState = boneStates[dropped.boneKey];

      const satInjectionState = liveInjectionNumber > turnInjectionNumber;
      const satPlayState = liveBoneCount > turnBoneCount;

      if (!cheatHado && !isMyTurn) {
        return dropped.resetPosition();
      }

      if (
        !cheatHado &&
        dropInBoneState &&
        dropInBoneState.boneCount <= turnBoneCount
      ) {
        return dropped.resetPosition();
      }

      if (wellPlatform) {
        return dropped.resetPosition();
      }

      if (platform) {
        if (!inLocalHand) {
          // no stealing others bones
          return dropped.resetPosition();
        }
        const boneSort = getLocalFeature("boneSort");

        const fromBoard = dropped.stateParentId === "board";
        if (boneSort) {
          const sorthand = localHand
            .filter((bone) => bone.boneKey !== dropped.boneKey)
            .map((bone) => {
              const gameBoneRef = scene.getObjectByName(
                `boneMesh-${bone.boneKey}`
              );

              return {
                boneKey: bone.boneKey,
                position: gameBoneRef.position,
              };
            });

          const droppedRef = scene.getObjectByName(
            `boneMesh-${dropped.boneKey}`
          );

          const xSorted = [
            { boneKey: dropped.boneKey, position: droppedRef.position },
            ...sorthand,
          ]
            .sort((a, b) => a.position.x - b.position.x)
            .map(({ boneKey }) => {
              if (boneKey === dropped.boneKey) {
                const droppedLocal = localHand.find(
                  (bone) => bone.boneKey == boneKey
                );

                const { initPosition = {} } = droppedLocal;

                const rotation = fromBoard
                  ? 0
                  : calcGridROtaion(droppedRef.rotation.y);

                const droppedRotationUpdate = {
                  ...droppedLocal,
                  initPosition: {
                    ...initPosition,
                    rotation,
                  },
                };

                return {
                  ...droppedRotationUpdate,
                };
              }

              return localHand.find((bone) => bone.boneKey == boneKey);
            });

          onHandSort({ hand: xSorted });
        }
        if (fromBoard) {
          return removeBone(boneItem.boneKey);
        }
      }

      if (board) {
        if (!cheatHado && satPlayState && !dropInBoneState) {
          return dropped.resetPosition();
        }

        onEnd({
          glyphs,
          boneItem,
        });
      }
    },
    [
      onEnd,
      localHand,
      boneStates,
      turn,
      removeBone,
      isMyTurn,
      scene,
      onHandSort,
    ]
  );

  const showHand = useMemo(
    () => !hidePlatforms && !showWell && turn && turn.state !== "pickBones",
    [hidePlatforms, showWell, turn]
  );

  useEffect(() => {
    if ((showHand || showWell) && !hidePlatforms) {
      setCurrentPlatform(showHand ? "hand" : "well");
    }
  }, [showHand, showWell]);

  return (
    <>
      {lowFiv1 ? (
        <></>
      ) : (
        <>
          <directionalLight
            intensity={0.5}
            color="white"
            position={[10, 10, 10]}
          />
          <directionalLight
            intensity={0.5}
            color="white"
            position={[-10, 10, 10]}
          />
          <directionalLight
            intensity={0.5}
            color="white"
            position={[10, 10, -10]}
          />
          <directionalLight
            intensity={0.5}
            color="white"
            position={[-10, 10, -10]}
          />
          <directionalLight
            intensity={0.1}
            color="white"
            position={[2, 10, 2]}
          />
          <directionalLight
            intensity={0.1}
            color="white"
            position={[-2, 10, 2]}
          />
          <directionalLight
            intensity={0.1}
            color="white"
            position={[2, 10, -2]}
          />
          <directionalLight
            intensity={0.1}
            color="white"
            position={[-2, 10, -2]}
          />
          <directionalLight
            intensity={0.1}
            color="white"
            position={[0, 0, 0]}
          />
        </>
      )}

      {/* <spotLight 
            intensity={50}
             position={[0, 0, 0]}
            //   angle={0.2}
            //    penumbra={1} 
               castShadow
                /> */}

      {/* <hemisphereLight intensity={4} />
            <ambientLight intensity={4} /> */}

      {/* <rectAreaLight
                width={3}
                height={3}
                //   color={color}
                intensity={10}
                position={[0, 0, 1]}
                lookAt={[0, 0, 0]}
                penumbra={2}
                //   castShadow
                /> */}
      <OrbitControls
        {...registerOrbitalRef()}
        minDistance={0.5}
        maxDistance={3}
        maxAzimuthAngle={0}
        minAzimuthAngle={0}
        maxPolarAngle={Math.PI / 3}
      />
      <WellPlatform showWell={!hidePlatforms && showWell} />
      <HandPlatform showHand={showHand} />
      {/* <SpecPoint/> */}
      <BaseWellMesh
        scale={drawBones.length > 0 ? 1 : 0}
        // scale={isMyTurn && drawBones.length > 0 ? 1 : 0}
      >
        {drawBones.map((bone) => {
          const { active, ...boneData } = bone;
          return (
            <BoneSwitch
              mode="firstPick"
              active={!!selectedDrawbones[bone.boneKey]}
              // mode={isMyTurn ? "firstPick" : "firstPickWait"}
              // active={active}
              bone={boneData}
              key={bone.boneKey}
              onPick={() => {
                onDrawBoneSelect(boneData);
              }}
            />
          );
        })}
      </BaseWellMesh>
      <mesh name="bonebay" position={[100, 100, 100]} scale={0}>
        {bones.map((bone) => {
          const { mode, active, ...boneData } = bone;

          return (
            <BoneSwitch
              mode={mode}
              active={active}
              bone={boneData}
              setShowPalette={setShowPalette}
              key={bone.boneKey}
              // onDoubleClick={(dubpac) => {
              //   setControlMoveBone(dubpac)
              // }}
              onPick={() => {
                const liveBoneCount = Object.keys(boneStates).length;
                const turnBoneCount = turn.boneCount;
                const satPlayState = liveBoneCount > turnBoneCount;
                if (isMyTurn && !satPlayState) {
                  selectDrawWellbone(bone);
                  const well = [
                    ...drawWell.filter(
                      (wellbone) => !bone.boneKey.includes(wellbone.boneKey)
                    ),
                  ];

                  const nextBone = {
                    ...boneData,
                    initPosition: {
                      ...boneData.initPosition,
                      rotation: 0,
                    },
                  };

                  bone.active = true;
                  setTimeout(() => {
                    setPlayerHand({
                      unlockShowWell,
                      endWellPick: true,
                      selectedHand: [nextBone, ...localHand],
                      well,
                    });
                    setunlockShowWell(false);
                  }, 500);
                }
              }}
              onDrop={(dropped) => onDrop(dropped)}
            />
          );
        })}
      </mesh>
      <CohadoBoardMesh
        togglePlatformControl={togglePlatformControl}
        boardRotation={boardRotation}
      >
        <MoveHexes />
        <Cursors />
        <mesh>
          <HexGrid />
        </mesh>
      </CohadoBoardMesh>
    </>
  );
};

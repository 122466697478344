import * as THREE from "three";
import { TabPane } from "./TabPane";
import { useFrame } from "@react-three/fiber";
import { useTexture } from "@react-three/drei";
import { useTouchHooks } from "../ThreeUtills/useTouchHooks";
import { useMemo, useRef } from "react";
import { registerPlatform } from "../PlatformControl/useDragPlatBlock";

export const HandPlatform = (props) => {
  const { showHand } = props;
  const platform = useRef();

  const { onPointerDown, onPointerMove, onPointerUp } = useTouchHooks();

  const scale = useMemo(() => {
    const { userData = {} } = platform.current || {};
    const { selectedScale = 0 } = userData;
    return selectedScale + 0.13;
  }, [platform]);

  useFrame(({ camera }) => {
    if (platform && platform.current) {
      const { userData } = platform.current; //
      let { selectedZ = 0, selectedY = 0, selectedX = 0 } = userData;
      platform.current.position.copy(camera.position);
      platform.current.quaternion.copy(camera.quaternion);

      platform.current.translateZ(selectedZ + -0.2);

      platform.current.translateY(selectedY + -0.1);
      platform.current.translateX(selectedX);
      platform.current.rotateX(90 * (Math.PI / 180));
    }
  });
  const boneBarTexture = useTexture("./images/boneBar.png");

  return (
    <mesh
      scale={showHand ? scale : 0}
      name="platform"
      onPointerDown={onPointerDown}
      onPointerMove={onPointerMove}
      onPointerUp={onPointerUp}
      ref={(ref) => {
        if (ref) {
          platform.current = ref;
          registerPlatform("hand", ref);
        }
      }}
    >
      <boxGeometry args={[3.2, 0, 0.4]} />
      <meshBasicMaterial
        attach="material"
        map={boneBarTexture}
        // opacity={0}
        transparent
      />
      {/* <TabPane/> */}
    </mesh>
  );
};
